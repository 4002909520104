@import "config";
@import "utilities";
@import "item_grid";

/* Reset */
* {
  margin: 0;
  padding: 0;
}

/*General styles */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  background: #fff;
  min-width: 290px;
}

a {
  text-decoration: none;
  color: $dark-color;
}

ul {
  list-style: none;
}

h2,
h3,
h4 {
  text-transform: uppercase;
}

img {
  width: 100%;
}

/* Logo */
#logo {
  width: 5rem;
  height: auto;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50%;
  transform: scale(0.7);
}

#main-nav {
  height: auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ul {
    display: flex;
  }
  li {
    padding: 1rem 1.5rem;
  }
  & > a.logo-wrap {
    padding-bottom: 0;
    border-bottom: 0;
  }

  a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    border-bottom: 3px transparent solid;
    padding-bottom: 0.5rem;
    transition: all 0.5s;
    text-shadow: #aba795 1px 1px 3px;

    &:hover {
      font-size: 115%;
    }

    &.current {
      border-color: $main-color;
    }
  }
}

/* Header */
#header {
  position: relative;
  /*&-home:before {
    content: "";
    position: absolute;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }*/
  &-home {
    background-color: #000;
    position: relative;
    height: 100vh;
    color: #fff;
    z-index: 1;

    /* sidebar navigation */
    #sidebar {
      position: absolute;
      top: 0;
      left: -201px;
      height: 100vh;
      width: 200px;
      background: rgba(51, 51, 51, 0.3);
      border-right: 1px solid rgba(242, 239, 239, 0.3);
      transition: transform 1s ease-in-out;

      .sb-logo-wrapper {
        justify-content: space-evenly;
        display: flex;
        height: auto;
        padding: 1rem;

        #sidebar-logo {
          width: 25%;
          height: 25%;
          border-radius: 50%;
        }
      }

      h2 {
        text-align: center;
        font-size: 1rem;
        margin-top: 0.75rem;
        color: #bebebe;
      }

      &.display-menu {
        transform: translateX(201px);
        transition: transform 1s ease-in-out;
      }
      p {
        padding: 0 20px;
        font-size: 0.8rem;
        line-height: 1.7;
        letter-spacing: 0.5px;
      }

      ul {
        margin-top: 0.75rem;
      }

      ul li {
        padding: 20px;
        border-bottom: 1px solid rgba(242, 239, 239, 0.3);
      }

      ul li:hover {
        cursor: pointer;
      }

      ul li:first-of-type {
        border-top: 1px solid rgba(242, 239, 239, 0.3);
      }
    }
    #intro {
      font-size: 0.875rem;
      transform: translateX(-100%);
      transition: transform 1s ease-in-out;
      img {
        display: block;
        border-radius: 50%;
        width: 50%;
        margin: 1rem auto;
        padding: 10px;
      }
      .intro-leading {
        color: white;
        span {
          color: #f7ad01;
        }
      }
    }

    /* container */
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 100vh;
    }

    /* Header Content */
    .header-content {
      text-align: center;
      padding: 0;
      width: 100%;

      h1 {
        font-family: "Dosis", sans-serif;
        font-size: 4rem;
        line-height: 1.2;
        text-shadow: 0 0 10px black;
      }

      .lead {
        font-size: 1.6rem;
      }

      a:hover {
        transform: scale(1.1);
        transition: transform 600ms;
      }
      a {
        font-size: 1.1rem;
      }
    }
  }
  /* Header Inner */
  &-inner {
    background-color: $dark-color;
    height: 5.5rem;
    border: 3px solid $main-color;

    .container {
      height: 100%;
      #main-nav {
        justify-content: space-between;
        ul {
          align-items: center;
        }
      }
    }
  }
}

/* Home Sections */
#home {
  /* Home: Section a */
  &-a {
    .specials {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;

      .fas {
        color: $main-color;
        padding-bottom: 0.4rem;
      }

      h3 {
        margin-bottom: 0.7rem;
      }

      p {
        padding: 0.8rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }

  /* Home: Section b */
  &-b {
    .stats {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      li {
        line-height: 2;

        &.stats-title {
          font-size: 1.4rem;
        }

        &.stats-number {
          font-size: 1.6rem;
          font-weight: bold;
        }
      }

      div {
        padding: 3rem 0;
        color: white;

        &:nth-child(odd) {
          background-color: #333;
        }
        &:nth-child(even) {
          background-color: #f4a809;
        }
      }
    }
  }

  /* Home : Section c */
  &-c {
    margin-bottom: 5rem;
    .process {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      text-align: center;

      /* process-icon */
      &-icon {
        border-radius: 50%;
        background: $dark-color;
        color: set-text-color($dark-color);
        padding: 2rem;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        position: relative;
        transition: all 1s;

        &:hover {
          background: $main-color;
          width: 90px;
          height: 90px;
          line-height: 90px;

          .process-step {
            background: $dark-color;
            color: set-text-color($dark-color);
          }
        }
      }

      /* process-step */
      &-step {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 28px;
        background: $main-color;
        color: set-text-color($main-color);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        line-height: 15px;
        padding: 1rem;
        transition: all 1s;
      }
    }
  }
}

/* About */
#about {
  /*About a */
  &-a {
    background-color: #80808024;
    .container {
      max-width: 700px;
    }
    .about-info {
      display: grid;
      grid-template-areas:
        "bioimage bio bio"
        "aw1 aw2 aw3";
      grid-gap: 1.2rem;

      h4 {
        margin-bottom: 1rem;
      }
    }

    /* Grid Areas */
    .bio-image {
      grid-area: bioimage;
      justify-self: flex-end;
      width: 85%;
    }

    .bio {
      grid-area: bio;
      border-left: 3px solid $main-color;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        text-align: left;
      }
    }

    .award-1 {
      grid-area: aw1;
    }

    .award-2 {
      grid-area: aw2;
    }

    .award-3 {
      grid-area: aw3;
    }

    .award-1 .fas,
    .award-2 .fas,
    .award-3 .fas {
      color: $main-color;
      margin: 1rem;
    }
  }

  /*About b */
  &-b {
    .container {
      max-width: 800px;
    }
    .bottom-line {
      margin-bottom: 3rem;
    }
    .progress {
      overflow: hidden;
      height: 20px;
      background: $medium-color;
      border-radius: 5px;
      margin-bottom: 0.75rem;

      div {
        height: 100%;
        color: #fff;
        text-align: center;
        background-color: $main-color;
      }
    }
  }

  /* About c */
  &-c {
    .about-logos {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;

      img {
        width: 70%;
      }
    }
  }

  /*About d */
  &-d {
    .testimonials {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 7.5rem;

      ul {
        list-style: none;
        margin-top: 1rem;
        display: flex;
        align-items: center;
      }

      p {
        border: 1px solid $medium-color;
        border-radius: 0.3rem;
        padding: 1.5rem;
      }

      img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
    }
  }
}

/*Contact Page */
#contact {
  &-a {
    .container {
      max-width: 600px;
    }
    .text-fields {
      display: grid;
      grid-template-areas:
        "name email"
        "subject phone"
        "message message";
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;

      .name-input {
        grid-area: name;
      }

      .email-input {
        grid-area: email;
      }

      .subject-input {
        grid-area: subject;
      }

      .phone-input {
        grid-area: phone;
      }

      .message-input {
        grid-area: message;
        height: 6rem;
      }

      .text-input {
        padding: 0.5rem 1rem;
        outline: none;
        border: 1px solid $faint-color;
      }
    }

    button[type="submit"] {
      width: 30%;
    }
  }

  &-b {
    .contact-info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
    }
  }

  &-c {
    h1 {
      text-align: center;
      font-size: 3rem;
    }
  }
}

/* Footer */
#main-footer {
  background: $dark-color;
  color: #fff;
  height: 5rem;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;

    .social .fab {
      margin-right: 1rem;
      border: 1px solid #fff;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      padding: 0.5rem;

      &:hover {
        background: $main-color;
      }
    }

    .social a {
      color: white;
    }
  }
}

@import "media";
