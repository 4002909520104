// Tablets and Smaller devices
@media (max-width: 1025px) {
  #header-home,
  #header-home:before,
  #header-home .container,
  #header-home #sidebar {
    height: 789px;
  }
  #header-home .header-content {
    padding-bottom: 2rem;
  }
}

@media (max-width: 800px) {
  #header-home,
  #header-home #sidebar,
  #header-home .container {
    height: 30rem;

    #intro {
      img {
        display: none;
      }
      .intro-leading {
        padding: 1rem;
      }
    }

    .header-content {
      //   margin-top: 1rem;
      //   padding-right: 2rem;
      h1 {
        font-size: 1.5rem;
      }
      //   display: none;
    }
  }

  #header-home:before {
    height: 30rem;
    min-width: 290px;
  }

  #contact-a {
    .container {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  #work-a .items {
    grid-template-columns: 1fr 1fr;
  }

  #about-d {
    .testimonials {
      grid-gap: 1rem;

      ul {
        flex-direction: column;
        margin-top: 2rem;
      }
    }
  }

  #contact-c {
    padding: 2rem 0;

    h1 {
      font-size: 2rem;
    }
  }
}

// Smart Phones
@media (max-width: 700px) {
  // home page
  #header-home {
    .container {
      padding: 0;
    }
    #main-nav {
      height: auto;
      ul {
        display: flex;
        flex-direction: column;
        margin-top: 57px;
        transition: all 1s ease-in-out;
        font-size: 0.9rem;
        li {
          padding: 1.5rem 0.5rem 1.5rem 0.5rem;
          background: #706f6845;
          border: 1px solid #ffffff5e;
          text-align: center;
        }
      }
    }

    .header-content {
      padding-right: 2rem;
      text-align: right;
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}

// Smart Phones
@media (max-width: 630px) {
  // Home Page Header
  #header {
    &-home {
      .container {
        justify-content: unset;
      }

      .header-content {
        display: none;
      }
    }
    &-inner {
      height: 9rem;

      #logo {
        width: 5rem;
      }

      #main-nav {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        a {
          margin-top: 1rem;
        }
        ul {
          margin-top: -3rem;
        }
        li {
          padding: 1rem;
        }
      }
    }
  }

  // Stack grid columns
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #work-a .items,
  #contact-b .contact-info {
    grid-template-columns: 1fr;
  }

  // Home
  #home {
    &-a {
      .specials div {
        border-bottom: 1px solid $light-color;
        padding-bottom: 1rem;

        &:last-child {
          border: none;
        }
      }
    }

    &-b {
      .stats div {
        padding: 1.5rem;
      }
    }
  }

  // About
  #about {
    &-a {
      .about-info {
        grid-template-areas:
          "bioimage"
          "bio"
          "aw1"
          "aw2"
          "aw3";

        .bio-image {
          display: block;
          margin: auto;
          width: 50%;
          border-radius: 50%;
        }
      }
    }

    &-c {
      display: none;
    }
  }

  //Contact
  #contact {
    &-a {
      .text-fields {
        grid-template-areas:
          "name"
          "email"
          "subject"
          "phone"
          "message";
      }
      button[type="submit"] {
        width: 98%;
      }
    }

    &-b {
      div {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $faint-color;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border: none;
        }
      }
    }

    &-c {
      h1 {
        font-size: 2rem;
      }
    }
  }

  //Footer
  #main-footer {
    height: 8rem;

    .footer-content {
      flex-direction: column-reverse;
      padding: 1.5rem;
      height: 5rem;
      p {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 400px) {
  #header {
    &-inner {
      height: 7rem;
      #main-nav {
        // li {
        //   padding: 1.6rem 0.5rem;
        // }
        ul {
          display: none;
        }
      }
    }
  }
}
// Landscape
@media (max-height: 580px) {
  #header-inner {
    height: 11rem;
  }
  #header-home {
    .header-content {
      padding-bottom: 0;
      text-align: right;
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}
@media (max-height: 330px) {
  #header-home {
    .header-content {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
// Something
