.items{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	box-shadow: 0 5px 10px rgba(0,0,0,0.3)
}

.item{
	position: relative;
	background: $main-color;
	overflow: hidden;
	border: solid 1px white;

	&-image {
	filter: brightness(0.9);
	transition: all 750ms ease;
	}
	

	a {
		transition: all 750ms ease;
	}

	a:hover {
		text-decoration: none;
		color: white;
	}


	//scale image on hover
	&:hover &-image{
		opacity: 1;
		transform: scale(1.2);
		transition: all 750ms ease;
	}

	&-text{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 1;
		text-align: center;
		color: #fff;
		transition: opacity 500ms;
	}

	&-text-wrap{
		position: absolute;
		width: 100%;
		bottom: 0%;
		background: $main-color;
		padding: 0.6rem 0;
	}

	&-text-category, &-text-title{
		line-height: 1;
	}

	&-text-category {
		padding-bottom: 5px;
	}
	
}