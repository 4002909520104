.container {
  max-width: $website-width;
  padding: 0 1rem;
  margin: auto;
  overflow: hidden;
}

/* spinner */
.spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 10;
  transform: translateX(50vw) translateY(50vh);
}

.spinner div {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid transparent;
  border-top-color: #c6cbb9;
  border-right-color: #c6cbb9;
  border-bottom-color: #c6cbb9;
  border-radius: 100%;
  animation: spinnerOne 1.2s linear infinite;
  display: none;
  opacity: 0;
}

@keyframes spinnerOne {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Active */
.active {
  opacity: 1;
  display: block;
}

/* Buttons */
%btn-shared {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

.btn {
  &-main {
    @extend %btn-shared;
    color: #333;
    background-color: $main-color;
  }

  &-light {
    @extend %btn-shared;
    color: #333;
    background-color: $light-color;
  }

  &-dark {
    @extend %btn-shared;
    color: #f4f4f4;
    background-color: $dark-color;
    border-radius: 5px;
  }
}

button[class^="btn-"]:hover,
a[class^="btn-"]:hover,
input[class^="btn-"]:hover {
  background-color: $main-color;
}

/* Hamburger menu */
#toggle-menu {
  border-radius: 0 5px 5px 0;
  background-color: rgba(128, 128, 128, 0.3);
  height: 35px;
  padding: 8px 12px;
  transform: translateX(201px);
  transition: transform 1s ease-in-out;
  z-index: 10;
  position: absolute;

  &.slide-toggle {
    transform: translateX(0);
    transition: transform 1s ease-in-out;
  }

  .toggle-line {
    border-bottom: 3px solid #fff;
    width: 25px;
    height: 1px;
    border-radius: 2px;
    padding: 3px 0;
  }
}

/* Background */
.bg {
  &-main {
    background: $main-color;
    color: set-text-color($main-color);
  }

  &-dark {
    background: $dark-color;
    color: set-text-color($dark-color);
  }

  &-light {
    background: $light-color;
    color: set-text-color($light-color);
  }

  &-medium {
    background: $medium-color;
    color: set-text-color($medium-color);
  }
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

/* Padding */
.py {
  &-1 {
    padding: 1rem 0;
  }

  &-2 {
    padding: 2rem 0;
  }

  &-3 {
    padding: 3rem 0;
  }

  &-4 {
    padding: 4rem 0;
  }
}

/* Margin */
.my {
  &-1 {
    margin: 1rem 0;
  }

  &-2 {
    margin: 2rem 0;
  }

  &-3 {
    margin: 3rem 0;
  }

  &-4 {
    margin: 4rem 0;
  }
}

.section-title {
  font-family: "Dosis", sans-serif;
  font-size: 2rem;
  display: block;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.bottom-line {
  height: 2px;
  width: 3rem;
  background: $main-color;
  display: block;
  margin: 0 auto 1rem auto;
}

/* Section a, initial state, in blank */
.section-reveal {
  position: relative;
  transform: translateY(150px);
  -webkit-transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
  -webkit-transition: 2s all ease;
}

.section-active {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}