// Variables 
$website-width: 1280px;
$main-color: #ffbc00;
// $main-color: #b48ee0;
// $main-color: #e08ecb;
// $main-color: #c43167;
// $main-color: #e73850;
// $main-color: #266ad0;
// $main-color: #26c8d0;
// $main-color: #81b140;
// $light-color: #f4f4f4;
$light-color: #f4f4f4;
$dark-color: #333;
// $dark-color: #442d4e;
// $dark-color: #2c033e;
// $dark-color: #280a0a;
// $dark-color: #131f2c;
// $dark-color: #051d38;
// $dark-color: #1d3c3c;
// $dark-color: #41354f;
$medium-color: #ccc;
$faint-color: #555;
$bg-image: url(../img/showcase.jpg);


// Set Text Color
@function set-text-color($color){
	@if(lightness($color) > 80){
		@return #000;
	} @else{
		@return #fff;
	}
}